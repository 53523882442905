.table-input{
    outline: none;
    border-radius: 0.3rem;
    border: none;
    border-color:#dadada ;
    padding: 0 7px;
}
.table-input:focus{
    outline: none;
    /* padding: 0.30rem 0; */
    height: 2.5rem;
    transition: ease 0.5s;
    border: 1px solid #dadadaa9;
}

/* .select__control{
    margin: 0 auto !important;
    border-style: none !important;
} */

.select__indicator-separator{
display: none !important;
}

.select__placeholder{
    /* display: none !important; */
}