#grouptherapyTableID th{text-align: center;}
#grouptherapyTableID td:nth-child(1),
#grouptherapyTableID td:nth-child(3),
#grouptherapyTableID td:nth-child(4),
#grouptherapyTableID td:nth-child(5),
#grouptherapyTableID td:nth-child(6){
    text-align: center;
}
#grouptherapyTableID td:nth-child(2)
#grouptherapyTableID td:nth-child(7){
    text-align: left;
}
// th, td {
//     border: 1px solid black;
//     border-collapse: collapse;
    
//   }
  .newtable td,.newtable th{
    border: 1px solid #666;
   
    
  }
 

