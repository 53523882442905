

#meetingTableID td:nth-child(2) #meetingTableID td:nth-child(7) {
  text-align: left;
}

.modal-btn {
  display: flex;
  align-items: center;
  margin-top: 25px !important;
  padding-left: 0 !important;
}
.present-div {
  margin-bottom: -15px !important;
}

#aimeeting-attendance th,
#aimeetingTableID th {
  text-align: center;
}

#aimeeting-attendance td:nth-child(1),
#aimeeting-attendance td:nth-child(5),
#aimeetingTableID td:nth-child(1),
#aimeetingTableID td:nth-child(5) {
  text-align: center;
}
#aimeeting-attendance td:nth-child(2) {
  text-align: left;
}

@media only screen and (max-width: 992px) {
  .modal-btn {
    margin-top: 5px !important;
    margin-left: 15px;
  }
  .present-div {
    margin-bottom: 0 !important;
  }
}
