.create-btn{
    margin-top: 1.84rem !important;
}

.download-btn{
    color: rgb(73, 80, 87);
}
.download-btn:hover{
color: #49504E;
}

@media only screen and (max-width:768px) {
    .create-btn{
        margin-top:0!important;
    }
}