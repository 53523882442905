.table_income {
    border: 1px solid #ddd !important;
}
#billTableID th{text-align: center;}
#billTableID td:nth-child(1),
#billTableID td:nth-child(2),

#billTableID td:nth-child(4),

#billTableID td:nth-child(7){
    text-align: center;
}
#billTableID td:nth-child(6),
#billTableID td:nth-child(5),
#billTableID td:nth-child(3){
    text-align: left;
}