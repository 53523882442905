.hover-effect{
    transition: ease 0.2s;
}

.hover-effect:hover{
    transform: scale(1.03);
    cursor: pointer;
}


.my-container{
    display: grid;
    grid-template-columns: repeat(5,auto);
    gap: 1rem;
}

.cross-sign{
    position: relative;
    margin-top: 8px;
}

.cross-sign::before{
    content: '';
    /* background-color: red; */
    border: 5px solid #B04759;
    width: 60px;
    position: relative;
    /* top: -21px; */
    padding: 5px;
    right: 0;
    height: 60px;
    border-radius: 50%;
}

.cross-sign::after{
    content: '';
    width: 5px;
    height: 45px;
    /* padding: 3px; */
    top: -6px;
    background: #B04759;
    -webkit-transform: rotate(45deg);
    transform: rotate(-45deg);
    position: absolute;
    left: 25px;
}
.transition-effect{
    transition: ease 0.3s;
}

.link-hover{
    color: #74788d !important;
}
.link-hover:hover{
    color: #5b73e8 !important;
    text-decoration: underline !important;
    cursor: pointer;
}

@media screen and (max-width:1160px) {
    .my-container{
        grid-template-columns: repeat(4,auto);
    }
}

@media screen and (max-width:992px) {
    .my-container{
        grid-template-columns: repeat(3,auto);
    }
}

@media screen and (max-width:768px) {
    .my-container{
        grid-template-columns: repeat(2,auto);
    }
}

@media screen and (max-width:600px) {
    .my-container{
        grid-template-columns: repeat(1,auto);
    }
}