
#medicationTableID th{text-align: center;}
#medicationTableID td:nth-child(1),
#medicationTableID td:nth-child(2),
#medicationTableID td:nth-child(6){
    text-align: center;
}
#medicationTableID td:nth-child(3)
#medicationTableID td:nth-child(4){
    text-align: left;
}

