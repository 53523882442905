#creditDebitId td:nth-child(4),
#creditDebitId td:nth-child(5){
    text-align: right;
};
#creditDebitId td:nth-child(7){
    text-align: left;
};
#creditDebitId th{
    text-align: center;
}
#creditDebitId td:nth-child(1),
#creditDebitId td:nth-child(2){
    text-align: center;
};