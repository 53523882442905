#staff-data-table {
  td:nth-child(2),
  td:nth-child(5),
  td:nth-child(6) {
    text-align: left;
  }
}

@for $i from 1 through 20 {
  .my-text#{$i} {
    font-size: #{$i}rem;
  }
}

.view-table {
  font-size: 1.08rem;
  font-weight: 400;
}
.view-table .table-bold {
  font-weight: 500;
}

#viewTable {
  width: 70%;
  //   margin: 0 auto;
  //   border-spacing: 15px;
}

#viewTable td:nth-child(2) {
  text-align: left;
}

@media only screen and (min-width: 1200px) {
  .xl-mt {
    margin-top: 1.8rem !important;
  }
  .xl-pr {
    padding-right: 0 !important;
  }
}
