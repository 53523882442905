#table_transaction_log td:nth-child(6), #table_transaction_log td:nth-child(7), #table_transaction_log td:nth-child(8){
    text-align: right;
  }
#table_transaction_log td:nth-child(2),
#table_transaction_log td:nth-child(3){
  min-width:97px;
}
#table_transaction_log td:nth-child(10){
  max-width:200px;
}
#table_transaction_log td:nth-child(1),
#table_transaction_log td:nth-child(2),
#table_transaction_log td:nth-child(3){
  text-align: center;
}
#table_transaction_log td:nth-child(11){
  text-align: left;
}