.marquee-container {
    width: 100%;
    overflow: hidden;
  }
  
  .marquee-content {
    display: inline-block;
    white-space: nowrap;
    animation: marquee 20s linear infinite;
  }

  .marquee-container:hover .marquee-content {
    animation-play-state: paused;
  }
  
  
  @keyframes marquee {
    0% {
      transform: translateY(0%);
    }
    20% {
      transform: translateY(-10%);
    }
    40% {
      transform: translateY(-30%);
    }
    60% {
      transform: translateY(-50%);
    }
    80% {
      transform: translateY(-70%);
    }
    100% {
      transform: translateY(-100%);
    }
  }
  