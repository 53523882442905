.scroll-bar{
  max-height: 40.2rem;
  overflow: scroll;
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

.scroll-bar::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 10px;
}

.scroll-bar::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}

.scroll-bar::-webkit-scrollbar-thumb{
	border-radius: 10px;
	background-image: -webkit-gradient(linear,
									   left bottom,
									   left top,
									   color-stop(0.44, #007092),
									   color-stop(0.72, #007192d6),
									   color-stop(0.86, #007192));
}

.submit-btn-fixed{
	position: absolute !important;
	bottom: 0;
	right: 1rem;
	margin-right: 1.2rem;
	max-width: 48.5rem !important;
	background-color: white;
}

@media only screen and (max-width:760px){
	.submit-btn-fixed{
	max-width: 480px !important;
	}
}

@media only screen and (max-width:560px){
	.submit-btn-fixed{
	max-width: 315px !important;
	}
}

@media only screen and (max-width:380px){
	.submit-btn-fixed{
	max-width: 300px !important;
	}
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}