.overflowScroll{
    top: 60px;
    width: 19.6rem;
    max-height: 177px;
    background-color: white;
    overflow: scroll;
    overflow-y: auto ;
    overflow-x: hidden;
    position: absolute;
}

.overflowScroll::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 10px;
}

.overflowScroll::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}

.overflowScroll::-webkit-scrollbar-thumb{
	border-radius: 10px;
	background-image: -webkit-gradient(linear,
									   left bottom,
									   left top,
									   color-stop(0.44, #007092),
									   color-stop(0.72, #007192d6),
									   color-stop(0.86, #007192));
}