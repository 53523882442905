#medicalreviewTableID th{text-align: center;}
#medicalreviewTableID td:nth-child(1),
#medicalreviewTableID td:nth-child(2),
#medicalreviewTableID td:nth-child(4){
    text-align: center;
}
.prev_table {
    border: 1px solid #666; 
}
.prev_table td,.prev_table th{
    border: 1px solid #666; 
}
.prev_table1 {
    border: 1px solid #666; 
}
.prev_table1 td,.prev_table1 th{
    border: 1px solid #666; 
}
