#admissionTableId th {
  text-align: center;
}

#admissionTableId td:nth-child(1) {
  text-align: center;
}

#admissionTableId td:nth-child(3),
#admissionTableId td:nth-child(6),
#admissionTableId td:nth-child(5),
#admissionTableId td:nth-child(8),
#admissionTableId td:nth-child(9) {
  text-align: center;
}
#admissionTableId td:nth-child(4),
#admissionTableId td:nth-child(7) {
  text-align: left;
  min-width: 150px;
}
#admissionTableId td:nth-child(2) {
  text-align: center;
  min-width: 120px;
}

#export-details {
  .table-responsive {
    border: 1px solid;
  }

  table {
    font-size: 0.82rem;

    th {
      padding: 0.4rem 0.4rem !important;
      text-wrap: nowrap;
    }

    td {
      font-size: 0.8rem;
      padding: 0.4rem 0.4rem !important;
    }
  }

  ul {
    list-style-type: none;
  }
}

.printing-footer {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  font-size: 0.8rem;
  margin-right: 1.2rem;
  align-items: flex-end;
}

.printing-header {
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 1.2rem;
}
