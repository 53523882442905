
#assessmentTableID th{text-align: center;}
#assessmentTableID td:nth-child(1),
#assessmentTableID td:nth-child(3),
#assessmentTableID td:nth-child(4),
#assessmentTableID td:nth-child(5),
#assessmentTableID td:nth-child(6){
    text-align: center;
}
#assessmentTableID td:nth-child(2)
#assessmentTableID td:nth-child(7){
    text-align: left;
}
th, td {
    border: 1px solid black;
    border-collapse: collapse;
    
  }
  .newtable td,.newtable th{
    border: 1px solid #666;
   
    
  }
 

