// 
// _footer.scss
// 

.footer {
    bottom: 0;
    padding: 20px calc(#{$grid-gutter-width} / 2);
    position: fixed;
    display: flex;
    align-items: center;
    right: 0;
    color: #74788d;//$footer-color;
    left: 220px;
    // height: $footer-height;
    height: 50px;
    background:white;
    box-shadow: 0 0px 4px rgba(15,34,58,.12);
    
}

@media (max-width:1200px) {
    .footer{
        display: static;
        height: $footer-height;
    }
}
  
@media (max-width: 992px) {
    .footer {
        left: 0;
    }
}

// Enlarge menu
.vertical-collpsed {
    .footer {
        left: $sidebar-collapsed-width;
    }
}

body[data-layout="horizontal"] {
    .footer {
        left: 0 !important;
    }  
}