.form-controls{
    width: 6rem !important;
}
.form-control1{
    width: 5rem !important; 
    line-height: 1.3px !important;
    
}
.form-control2{
    width: 19rem !important; 
    //line-height: 1.3px !important;
    
}
.form-control3{
    line-height: 0.6px !important;
    width: 10rem !important; 

}
.form-control5{
    line-height: 0.6px !important;
    width: 14rem !important; 

}