#enquiryTableId th {
    text-align: center;
}

#enquiryTableId td:nth-child(2) {
    width: 116px;
    text-align: center;
}

#enquiryTableId td:nth-child(4),
#enquiryTableId td:nth-child(7),
#enquiryTableId td:nth-child(9) {
    width: 100px;
    text-align: center;
}

#reg_mobile .select2-selection__indicators {
    display: none;
}

#enquiryModalTable {
    width: 100%
}

#followDataTableId th {
    text-align: center;
}

#followDataTableId td:nth-child(1) {
    width: 50px;
    text-align: center;
}

#followDataTableId td:nth-child(2) {
    width: 300px;
    text-align: center;
}

#followDataTableId td:nth-child(4) {
    width: 120px;
    text-align: center;
}

#followDataTableId td:nth-child(5) {
    width: 100px;
    text-align: center;
}

#followDataTableId td:nth-child(7) {
    width: 90px;
}

#followDataTableId td:nth-child(6),
#followDataTableId td:nth-child(8) {
    width: 150px;
    text-align: left;
}

.custbadge {
    cursor: default !important;
    padding: 3px;
}