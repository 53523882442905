.p-5 {
  padding: 0.5rem !important;
}
.table1 td,
.table1 th {
  border: 1px solid #666;
}
.test_class {
  // height: auto !important;
  // min-height: 38px !important;
  max-height: none !important;
}
// .select2-selection__control{
//     max-height: none !important;
// }

.test-result-table1 {
  th,
  td {
    padding: 0.5rem;
    border: 1px solid;
  }
}
