.table-link-hover{
    color:#495057 !important;
}
.table-link-hover:hover{
    color: #5b73e8 !important;
    text-decoration: underline !important;
    cursor: pointer !important;
    text-underline-offset: 2px !important;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button{
    -webkit-appearance: none;
}
