#manageTestTableId th{text-align: center;}
#manageTestTableId td:nth-child(1),
#manageTestTableId td:nth-child(2),
#manageTestTableId td:nth-child(3){
    text-align: center;
}
#manageTestTableId td:nth-child(4),
#manageTestTableId td:nth-child(6){
    text-align: left;
}
#manageTestTableId td:nth-child(5){
    text-align: right;
}
.action-icon{
    cursor: pointer;
}
.add-new-span{
    cursor: pointer;
    font-weight: 700;
    margin-right: 10px;
}
#testTableID td,#testTableID th{
    padding: 1px !important;
    text-align: center;
    vertical-align: middle;
}
.label-heading{
    font-size: 0.9rem;
    font-weight: 500;
}
.cust-error-div{
    display: block !important;
    margin:0px !important;
    padding: 0px !important;
    text-align: left;
}
.cust-error-fiels{
    border-color: #f46a6a !important;
}
.cust-success-fiels{
    border-color: #ced4da !important;
}

#manageTestTable th{
    text-align: center;
}

#manageTestTable th:nth-child(1),
#manageTestTable th:nth-child(2),
#manageTestTable th:nth-child(5),
#manageTestTable th:nth-child(4),
#manageTestTable td:nth-child(1),
#manageTestTable td:nth-child(2),
#manageTestTable td:nth-child(5),
#manageTestTable td:nth-child(6)
{
    vertical-align: middle;
}

#manageTestTable td:nth-child(1),
#manageTestTable td:nth-child(2),
#manageTestTable td:nth-child(3){
    text-align: left;
}
.align-action-icon{
    display: flex;
    justify-content: center;
    vertical-align: middle;
}