.new-row{
    font-size: 14px;
    padding: 3px;
    font-weight: 500;
}
.new-row:hover{
    transition: ease 0.2s;
    color: #007192;
    font-weight: 600;
    /* text-decoration: underline; */
    cursor: pointer;
}

.border_bottom{
    border-bottom: 1px solid black;
}

.trash-btn{
    cursor: pointer;
    font-size: larger;
}
.trash-btn:hover{
    color: #007192;
}

.save-continue-btn{
    background-color: #5b73e8;
    border-radius: 4px;
    padding: 8px 15px;
    display: inline-block;
    color: #fff;
    /* float: right; */
    border: none;
    outline: none;
    margin: 0 4px;
    cursor: pointer;
}

.save-continue-btn:hover{
    color: #fff;
}
.float-right{
    float: right;
    /* display: flex;
    align-items: center;
    justify-content: center; */
}

/* .select__control:hover{
border-color: #f46a6a !important ;
} */
/* .select__control:active{
    border-color: #ced4da !important;
} */

.invalid-field{
    width: 100%;
    margin-top: 0.25rem;
    font-size: 87.5%;
    color: #f46a6a;
}

@media screen and (min-width: 1200px) {
    .save-continue-btn{
        margin-bottom: 15px;
    }
  }