#tableDayBook th{
    text-align: center;
}
// #tableDayBook td:nth-child(2){
//     width: 160px;
// }
// #tableDayBook td:nth-child(5),
// #tableDayBook td:nth-child(6){
//     text-align: right;
// }
// #tableDayBook td:nth-child(3),
// #tableDayBook td:nth-child(5),
// #tableDayBook td:nth-child(6){
//     width: 100px;
// }
