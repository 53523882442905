.p-5{
    padding:0.5rem !important
}
.card{
    margin-top: 10px;
}
.p-4{
    padding:0.5rem !important 
}
#counsellingTableID th{text-align: center;}
#counsellingTableID td:nth-child(1),
#counsellingTableID td:nth-child(2),
#counsellingTableID td:nth-child(4),
#counsellingTableID td:nth-child(5){
    text-align: center;
}
#counsellingTableID td:nth-child(3){
    text-align: left;
}
.table2 td,.table2 th{
    border: 1px solid #666;
}