.table-input {
  outline: none !important;
  border-radius: 0.3rem !important;
  /* border: 1px solid #dadada; */
  /* border-color: #dadada !important; */
  padding: 0 7px !important;
}
.table-input:focus {
  outline: none !important;
  /* // padding: 0.30rem 0; */
  height: 2.5rem !important;
  transition: ease-in-out 0.5s !important;
  border: 1px solid #dadadaa9 !important;
}
/* table{
    width:100%;
}
table, th, td {
    border: 1px solid black !important;
  } */

.category-name {
  vertical-align: middle !important;
  writing-mode: tb !important;
  rotate: 180deg !important;
  letter-spacing: 1px !important;
}
.vertical-align-middle {
  vertical-align: middle !important;
}

#show-tranisition {
  transition: ease-in-out 0.5s;
}

#test-result-table th:nth-child(1), #test-result-table td:nth-child(1) {
  max-width: 1rem;
}
#test-result-table td:nth-child(2) {
  text-align: left;
}

.dashboard-link:hover{
    color: #0000ee;
    text-decoration: underline;
    cursor: pointer;
}
