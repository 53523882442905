
#incomeExpenseId td:nth-child(6){
    text-align: right;
};
#incomeExpenseId th{
    text-align: center;
}
#incomeExpenseId td:nth-child(1),
#incomeExpenseId td:nth-child(2),
#incomeExpenseId td:nth-child(6){
    text-align: center;
}
#incomeExpenseId td:nth-child(2){
    min-width: 97px;
}
#incomeExpenseId td:nth-child(9){
    max-width: 120px;
}
#incomeExpenseId td:nth-child(11){
    min-width: 130px;
}