#table_chartof_account td:nth-child(5){
  text-align: right;
}
.action{
    display: 'flex';
    justify-content: center;
}
.action > * {
    margin-left:0.5em ;
    font-size: '1em';
    cursor: pointer;
}