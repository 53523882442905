/* .table-input{
    outline: none !important;
    border-radius: 0.3rem !important;
    border: none !important;
    border-color:#dadada !important;
    padding: 0 7px !important;
}
.table-input:focus{
    outline: none !important;
   // padding: 0.30rem 0;
    height: 2.5rem !important;
    transition: ease 0.5s !important;
    border: 1px solid #dadadaa9 !important;
} */

.vertical-middle{
    vertical-align: middle !important;
}

.table-input-save {
    border: 1px solid #016e8f !important;
    outline: none !important;
    height: 2.5rem !important;
    transition: ease-in-out 0.5s !important;
  }